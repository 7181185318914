import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['icon']

  initialize() {
    this.config = { theme: 'light' }
    this.html = document.getElementsByTagName('html')[0];
  }

  connect() {
    let themeColorToggle = document.getElementById('light-dark-mode');
    console.debug("Dark Mode Controller Connected", themeColorToggle)
    this.setDefaultLayoutColor();
  }

  toggle(e) {
    if (this.config.theme === 'light') {
      this.changeLayoutColor('dark');
      this.iconTarget.classList.toggle('fa-sun', 'fa-moon');
    } else {
      this.changeLayoutColor('light');
      this.iconTarget.classList.toggle('fa-moon', 'fa-sun');
    }

    window.localStorage.setItem('dark-mode-theme', this.config.theme);

    // Send the preference to the backend
    this.updateUserPreference();
  }

  changeLayoutColor(color) {
    this.config.theme = color;
    this.html.setAttribute('data-theme', color);
  }

  loadConfig() {
    return window.localStorage.getItem('dark-mode-theme') || 'light';
  }

  setDefaultLayoutColor() {
    fetch('/api/v1/user_preferences', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          const darkModePreference = data.preferences.find(pref => pref.preference_key === 'dark_mode');
          if (darkModePreference) {
            this.changeLayoutColor(darkModePreference.preference_value.value);
            this.updateIcon(darkModePreference.preference_value.value);
          } else {
            const defaultTheme = this.loadConfig();
            this.changeLayoutColor(defaultTheme);
            this.updateIcon(defaultTheme);
          }
        } else {
          const defaultTheme = this.loadConfig();
          this.changeLayoutColor(defaultTheme);
          this.updateIcon(defaultTheme);
        }
      })
      .catch(error => {
        console.error('Error fetching dark mode preference:', error);
        const defaultTheme = this.loadConfig();
        this.changeLayoutColor(defaultTheme);
        this.updateIcon(defaultTheme);
      });
  }


  updateUserPreference() {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    fetch('/api/v1/user_preferences', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({
        user_preference: {
          preference_type: 'theme',
          preference_key: 'dark_mode',
          preference_value: { value: this.config.theme }
        }
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          console.log('Dark mode preference saved successfully');
        } else {
          console.error('Failed to save dark mode preference:', data.errors);
        }
      })
      .catch(error => {
        console.error('Error saving dark mode preference:', error);
      });
  }

  updateIcon(theme) {
    if (theme === 'dark') {
      this.iconTarget.classList.remove('fa-moon');
      this.iconTarget.classList.add('fa-sun');
    } else {
      this.iconTarget.classList.remove('fa-sun');
      this.iconTarget.classList.add('fa-moon');
    }
  }
}
