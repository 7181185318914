import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select"];

  connect() {
    this.originalValue = this.selectTarget.value;
  }

  updatePreference() {
    const selectedValue = this.selectTarget.value;

    if (selectedValue !== this.originalValue) {
      fetch("/api/v1/user_preferences", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
        },
        body: JSON.stringify({
          user_preference: {
            preference_type: "accounting",
            preference_key: "accounting_method",
            preference_value: { value: selectedValue },
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            this.originalValue = selectedValue;
          } else {
            console.error("Failed to update preference:", data.errors);
          }
        })
        .catch((error) => {
          console.error("Error updating preference:", error);
        });
    }
  }
}
